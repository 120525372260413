
export const Animated = require('./down_arrows_animated.webm')
export const Utopia_White = require('./logos/Utopia-Logo.webp')
export const Utopia_Black = require('./logos/Utopia-Logo-Black.webp')
export const Myramyth_White = require('./logos/Myramyth-Logo.webp')
export const Myramyth_Black = require('./logos/Myramyth-Logo-Black.webp')
export const Myramyth_SVG = require('./logos/Myramyth-SVG.svg')
export const Myramyth_FullWidth = require('./logos/Myramyth-Logo-FullWidth.png')
export const Myramyth_FullWidth_Black = require('./logos/Myramyth-Logo-FullWidth-Black.png')
export const Spinning_Ring = require('./Spinning-Ring.webm')
export const Logo_X = require('./socials/twitter.png')
export const Logo_Twitch = require('./socials/twitch.png')
export const Logo_Patreon = require('./socials/patreon.png')
export const Logo_Youtube = require('./socials/youtube.png')
export const Utopia_Cover = require('./Utopia_TTRPG_Cover.png')
export const Utopia_Character_Sheet = require('./Utopia_Char_Sheet.png')