import React from 'react';
import { Title, Text } from '@mantine/core';

const Story = () => (
  <div>
    <Title order={1} tt="uppercase" fs="bold">{metadata.title}</Title>
    <Title order={3} fs="bold">{metadata.date}</Title>
    <Title order={3} fs="bold" c={metadata.accent}>S-X.2.1</Title>
    <Title order={3} fs="bold">7 AC</Title>
    <Text>{`
Alyx took a second to catch their breath. Their blade was now stained with blood, the consistency of maroon ichor dripping from its edges. 

They did their best to filter through their thoughts, but the gaze of the creature in front of them made it difficult for them to focus. What did they know about hydras?

In adulthood, they'll metamorphosize into a [ruby] dragon. They're naturally regenerative. They're a part of the six lesser dragons, one of the most powerful of such.

As if sifting through a filing cabinet, they finally reached for something worthwhile: they move faster the more heads they have, though all heads will have to be dispatched in quick succession in order to truly doom the creature.

Another ball of fire lept towards their feet. A swift evasion left no more than the sleeve of their undershirt singed, maybe a bit of their  brown hair. The armor they crafted was putting in a lot of work in this fight, a metallic vest paired with greaves and boots. Some minor runes were scribed into each, helping them deter oncoming magics while also boosting their speed and reflexes.

A barrage of attacks came at them, but they still had some air in their lungs. With nothing shy of true athleticism, they juked and dodged every tooth of the ten-headed reptile and every curl of flame it spit.

Their mind raced for a swift conclusion, finally coming up with something. Their dirty hands rose in the air, clutching their blade tightly in one hand. A short incantation slipped through their lips, illuminating the blade. Ancient runes, ones that Alyx had read some time ago, carved themselves into the orichalcum that comprised the weapon.

It radiated with power. Though there was no worth in complaining, they were quite drained. The amount of energy for something so powerful was rather immense. If only there was time to worry about that now.

A shear of lightning followed the blade as they slashed, arcing across a blinding curve. Slow, meticulous, and yet all in only two seconds of time, the blade carved through each of nine necks, severing them from the dragon’s body.

Alyx was quickly drenched in anxiety, the realization hitting them much harder than the hydra did. The runes had dissipated, yet a single head remained on the beast. They didn't have the time to do that again, the creature will regenerate in soon time. 

In only a single second, expending the very small amount of energy they had left, a line of ice was drawn in the sky. Their hands began to glow with a radiant blue light as the monster connected with this glacial edge, waving it down through the final throat.

The cut was seamless, each head cleanly severed. Alyx fell to their knees, trying desperately to regain themselves for a second. Normally they wouldn't waste something valuable to rejuvenate themselves when they had time to spare, but unfortunately, time was a luxury here.

Conjuring a small injection device from their satchel, they stuck themselves in the left portion of their breast. Alyx's eyes widened for a moment as the immediate rush entered their blood, the plunger slowly depressing on the device. 

Wasting no time, they tossed away the syringe and whipped up their carving knife, meticulously cutting through the creature's flesh. Anything valuable; scales, teeth, eyes. Is the heart useful? Likely. What about the bones? Encumbering, but high potential, those can be done last. Remember to extract the blood this time, hopefully without disenchanting it.

The clock began to blare in their ears, 5, 4…

The meat of the creature isn't necessary. They have more than enough rations.

3…

This process would be best done in an hour, much less second. They'd be sweating if there was enough time to be doing so.

2…

The blood. Don't disenchant the blood. It's too useful to ruin this time around.

1…

Got it.

Slowly, the world melted around Alyx. A light static sound whirred in the air as the sky slowly dissolved, and the lush ground transformed into a rigid white floor.

The room around them offered little to look at. Perhaps 30 meters in diameter, essentially a giant white dome with no special features. The false paint it wore was washing away, leaving it to its much less eventful base.

A door slid open on the far end of the room, a small party of humanoid creatures levitated towards Alyx. They did it, the simulation was complete, and it was time for them to get ready for the next one.

—

Alyx has always been someone of little reaction, rarely surprised by much. Pia is a very large planet, and its colonized moon, Uto, only has more to offer. Though sometimes, they do find it difficult not to gawk at this opportunity they've been given.

It was simple in concept but incomprehensible in practice. Alyx would be sent through a series of simulated scenarios, generally focused around the defeat of a number of hostile creatures.

In return, whatever Alyx could gather from this simulated universe would be immediately reimbursed, allowing Alyx to craft something to prepare for their next encounter. They've been given eight hours this time around to prepare, enough to plan and manufacture something new for their arsenal. 

These hydra parts are going to be extremely helpful, thankfully they have the blood they needed.

The materials aren't real, they're all a part of each simulation. Though, they did promise that at the end of the simulations, Alyx would be gifted the true versions of each material; or at the very least, resources with similar quality and rarity.

Occulants truly are fascinating creatures, aren't they?

As Alyx wandered around the room, scribbling notes and constructing small parts, the image fluttered around their mind. The gigantic singular eye floating above their scrawny bodies, their skin so tight you can nearly see every bone.

Without mouths they only speak telepathically. Uncomfortable at first, extremely efficient once one gets used to it.

They seem to only hunger for knowledge and history. Every once in a while, Alyx will spot a small group of them toting back some type of ancient artifact that they’re moving to their collection, often led by a single captain. Sometimes magical tomes, other times it's just simply a necklace of some type.

On a fortunate day, they'll have a chance to study it themselves, though the occulants are quite meticulous and secretive about their findings. Generally a good reason will be needed, such as aiding in the design of the next item.

From what Alyx understands, this is the whole point of it all for them. They want to learn about these creatures, and they're able to use the knowledge they gather from these simulations and the newly constructed items to build even better virtual scapes. At the end of it all, they profit grand intuition. 

Curious, it is, why they picked Alyx Ploht specifically. 

—

The past few weeks seem to have declined in difficulty, which is rather odd. They rose up to the level of a kraken, which Alyx truly struggled to dispatch. However, after that point, the curve plummeted, ending most recently on a handful of minotaurs.

They pondered unto themself, presuming that the occulants are essentially cleaning up loose ends in their research. Perhaps wrapping up some data that wasn't fully captured before. Tonight begins the hiatus, so it only makes sense.

Since the odd creatures have an allegedly huge amount of work to complete before they can continue the simulated progress, Alyx will be departing for some time. They've been reimbursed for the items they crafted as promised, scheduled to return in a month.

Alyx finishes packing before making their way out the facility, quite the eyesore in the lush fields it occupies. It's been quite a while since they last saw such a beautiful night sky, or at least a real one.

Alyx’s footfalls gently crush the grass beneath their feet, the facility growing smaller and smaller into the distance, finally being swallowed up by the horizon.

One month of time seemed like the perfect amount to finally progress the temporal research they've been doing. The math was becoming quite promising, though they were still a breakthrough away from really solving anything useful.

Of course, Alyx didn't know that the facility wouldn't be there anymore in a month.

Hundreds of fleeting thoughts went through their mind while they traveled; the research and testing they've been doing, the new equipment weighing down their satchel and what they can do to make use of it. Most of all, the things that the one with blue eyes told them. Every once in a while, those words would echo in their ears, bounce around a bit, then finally escape into the vastness.

It felt like only moments had passed before the sky turned bronze, a single ray of light glimmering against the metal-plated roof of Alyx's home.

The Horn Range is a vast region with many powerful features, but the land was imprinted on their mind. Navigating was second nature at this point; the eastern deserts to the western grotto, they knew just about all of it.

That's what they thought to themselves, at least.

The machines were still beeping and yelling, tracking the flows of mana that passed through them, just as Alyx left them. Quickly skimming through the reports, nothing noteworthy seemed to have happened while they were gone.

Realistically, these machines were rather flawed. They weren't able to track other timelines like they were designed to do; at least, not yet. Perhaps if something truly catastrophic happened somewhen else they would serve a purpose, but the chance of that is essentially non-existent.

The machines roared to life.

Alyx subtly jumped, quite the anomaly given their experiences. In a flash, Alyx dropped everything and studied the machine, watching and processing every single number that flew through its five screens. There were a series of knobs and buttons on the central panel that they used to navigate the information. With extreme dexterity, they filtered through everything.

At first, it didn't make any sense. In order for this to really function, it would have to attach to something in another timeline. A literal universe of its own temporal existence independent of their own, but this was essentially a machine with no hands. How did it possibly grab onto something?

A signal being broadcasted like this is unspeakable, there's nothing Alyx could imagine that could possibly radiate something so powerful.

They deciphered the numbers, sifting through each message; what could this possibly be? Perhaps there was a malfunction in the machine. Quite a rare occurrence, but stranger things have happened. They'll have to recalibrate this thing, perhaps even tear it down to scrap and rebuild it from scratch. The thing barely serves a purpose anyways, they might as well–

“Alyx,” a soft voice invaded their ears. Alyx whips around to see a pair of blue eyes staring right through them. The icy gaze seemed much more purposeful than normal. Somebody of such gleeful demeanor being so serious was off putting.

The blue-eyed boy finally broke the single moment of silence, “You died, Alyx,” he said. A quick blink, “And, uh, I think you gotta fix it.”
    `}</Text>
  </div>
)

export const metadata = {
  title: 'Utopia',
  author: 'Orident',
  date: 'Sept 1, 2024',
  accent: '#0096ff',
  id: 'utopia',
  snippet: "We follow Alyx Ploht through their journeys with the occulants, a species of shapeshifting humanoids that crave extreme knowledge. Through a series of simulations, we see Alyx’s journey through a series of simulations, ultimately giving them an unparalleled level of combat experience and real-world resources. Upon returning home, they are approached by Orion (unnamed), telling them that they’ve died in other timelines, and that they’ll need to do something about it."
}

export default Story;