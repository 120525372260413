import React from "react";
import { Text, Card, SimpleGrid, Title } from "@mantine/core";

const Traits = (traitsArray) => {
  <SimpleGrid cols={3}>
    {traitsArray.map((trait) => {
      <Card>
        <Title order={2}>{trait.title}</Title>
        <Text>{trait.description}</Text>
      </Card>
    })}
  </SimpleGrid>
}

export default Traits;