import DiceRoller from './DiceRoller';
import { useState } from 'react';
import { motion } from 'framer-motion';

const DiceHandler = () => {

  const [diceArray, setDiceArray] = useState([{ id: 1, value: 1 }]);
  const [nextDiceId, setNextDiceId] = useState(2);

  const addDiceClick = () => {
    if (diceArray.length <= 5) {
      setDiceArray((oldArray) => [...oldArray, { id: nextDiceId, value: 1 }]);
      setNextDiceId((prevId) => prevId + 1);
    }
  };  

  const removeDiceClick = () => {
    if (diceArray.length > 1) {
      setDiceArray((oldArray) => oldArray.slice(0, -1));
    }
  };

  const updateDiceValue = (id, value) => {
    setDiceArray((oldArray) =>
      oldArray.map(dice => dice.id === id ? { ...dice, value } : dice)
    );
  };  

  const spawn = (count) => {
    let idCounter = nextDiceId;
    const newDice = Array.from({ length: count }, () => ({ id: idCounter++ }));
    setDiceArray((oldArray) => [...oldArray, ...newDice]);
    setNextDiceId(idCounter);
  };

  const clear = () => {
    setDiceArray([]);
  };

  const rollAll = () => {
    
  };

  const total = diceArray.reduce((sum, dice) => sum + dice.value, 0);

  const validateNumber = (input) => {
    let text = input.target.value;
    text = text.replace(/\D/g, '');
    input.target.value = text;
    const value = parseInt(text, 10);
    if (value > 20) {
      input.target.value = 20;
    } else if (value < -10) {
      input.target.value = -10;
    }
    const id = input.target.id;
    localStorage.setItem(id, input.target.value);
  };

  return (
    <motion.div
    initial={{ opacity: 0, y: -500 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -500 }}> 
      <div className="roller-buttons">
        <button onClick={() => {
          clear(); 
          spawn(3);
        }} className="roller-button">Standard Test</button>
      </div>
      <DiceRoller
        diceArray={diceArray}
        addDiceClick={addDiceClick}
        removeDiceClick={removeDiceClick}
        clear={clear}
        rollAll={rollAll}
        validateNumber={validateNumber}
        updateDiceValue={updateDiceValue}
      />
      <div className="roller-total">
        <h3>Total: {isNaN(total) ? '0' : total}</h3>
      </div>
    </motion.div>
  );
};

export default DiceHandler;