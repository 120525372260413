import Oxtus from './Oxtus.jsx';
import RegalOxtus from './RegalOxtus.jsx';

export const descriptions = [
  {
    id: "races/oxtus",
    component: Oxtus
  },
  {
    id: "races/regal_oxtus",
    component: RegalOxtus
  },
]

export const data = [
  {
    "label": "Stats and Talents",
    "value": "statsAndTalents",
    "children": [
      {
        "label": "Setting up Stats",
        "value": "statsAndTalents/setting_up_stats",
      },
      {
        "label": "Specialist Talents",
        "value": "statsAndTalents/specialist_talents",
      },
      {
        "label": "Calculating Modifiers",
        "value": "statsAndTalents/calculating_modifiers",
      },
      {
        "label": "Languages",
        "value": "statsAndTalents/languages",
      },
      {
        "label": "Travel Speeds",
        "value": "statsAndTalents/travel_speeds",
      }
    ]
  },
  {
    "label": "Races",
    "value": "races",
    "children": [
      {
        "label": "Human",
        "value": "races/human",
        "description": "Information about the human species."
      },
      {
        "label": "Automaton",
        "value": "races/automaton",
        "description": "Details about automatons as a playable species."
      },
      {
        "label": "Dwarf",
        "value": "races/dwarf",
        "description": "Information about dwarves as a species.",
        "children": [
          {
            "label": "Copper Dwarf",
            "value": "races/copper_dwarf",
            "description": "Characteristics of Copper Dwarves."
          },
          {
            "label": "Iron Dwarf",
            "value": "races/iron_dwarf",
            "description": "Characteristics of Iron Dwarves."
          },
        ]
      },
      
      {
        "label": "Cyborg",
        "value": "races/cyborg",
        "description": "Details about cyborgs as a species.",
        "children": [
          {
            "label": "Biotech Cyborg",
            "value": "races/biotech_cyborg",
            "description": "Features of Biotech Cyborgs."
          },
          {
            "label": "Cybernetic Cyborg",
            "value": "races/cybernetic_cyborg",
            "description": "Features of Cybernetic Cyborgs."
          },
        ]
      },
      {
        "label": "Oxtus",
        "value": "races/oxtus",
        "description": "Information about the Oxtus species.",
        "children": [
          {
            "label": "Regal Oxtus",
            "value": "races/regal_oxtus",
            "description": "Traits of Regal Oxtus.",
            "gifts": ["Memory", "Portrayal", "Appeal"],
            "scores": ["2d4 Block Rating", "2d12 Dodge Rating", "4 Constitution", "4 Endurance", "3 Effervescence"],
            "traits": [
              {
                "title": "Social Spores",
                "description": "The range at which you may communicate with creatures telepathically increases by 20 meters.",       
              },
              {
                "title": "Hyperphotosynthesis",
                "description": "When you successfully dodge any amount of Energy damage, you regain 1d4 stamina.",       
              }
            ]
          },
          {
            "label": "Brazen Oxtus",
            "value": "races/brazen_oxtus",
            "description": "Traits of Brazen Oxtus."
          },
          {
            "label": "Astute Oxtus",
            "value": "races/astute_oxtus",
            "description": "Traits of Astute Oxtus."
          },
        ]
      },      
      {
        "label": "Elf",
        "value": "races/elf",
        "description": "Details about elves as a species.",
        "children": [
          {
            "label": "Solar Elf",
            "value": "races/solar_elf",
            "description": "Characteristics of Solar Elves."
          },
          {
            "label": "Lunar Elf",
            "value": "races/lunar_elf",
            "description": "Characteristics of Lunar Elves."
          },
          {
            "label": "Twilight Elf",
            "value": "races/twilight_elf",
            "description": "Characteristics of Twilight Elves."
          },
        ]
      },
      {
        "label": "Cambion",
        "value": "races/cambion",
        "description": "Information about Cambions as a species.",
        "children": [
          {
            "label": "Angelic Cambion",
            "value": "races/angelic_cambion",
            "description": "Traits of Angelic Cambions."
          },
          {
            "label": "Demonic Cambion",
            "value": "races/demonic_cambion",
            "description": "Traits of Demonic Cambions."
          },
          {
            "label": "Eldritch Cambion",
            "value": "races/eldritch_cambion",
            "description": "Traits of Eldritch Cambions."
          }
        ]
      },
      
    ]
  },
  {
    "label": "Spells, Traits, and Items",
    "value": "spells,Traits,andItems",
    "children": [
      {
        "label": "Fireball",
        "value": "spells,Traits,andItems/fireball",
        "description": "A massive ball of fire."
      }
    ]
  },
  {
    "label": "Rules",
    "value": "rules",
    "children": [
      {
        "label": "Levelling Up",
        "value": "rules/levelling_up",
        "description": "Guidelines on how characters can level up."
      },
      {
        "label": "Turn Actions",
        "value": "rules/turn_actions",
        "description": "Rules for actions that can be taken during a turn."
      },
      {
        "label": "Rolls and Tests",
        "value": "rules/rolls_and_tests",
        "description": "Information on how rolls and tests are conducted."
      },
      {
        "label": "Standard Actions",
        "value": "rules/standard_actions",
        "description": "List of standard actions available to characters."
      },
      {
        "label": "Armor and Equipment",
        "value": "rules/armor_and_equipment",
        "description": "Details about armor and equipment."
      },
      {
        "label": "Concentration and Focus",
        "value": "rules/concentration_and_focus",
        "description": "Rules regarding concentration and focus during gameplay."
      },
      {
        "label": "Resting",
        "value": "rules/resting",
        "description": "Guidelines for resting and its effects."
      },
      {
        "label": "Ailments",
        "value": "rules/ailments",
        "description": "Information on ailments and how they affect characters."
      },
      {
        "label": "Travel",
        "value": "rules/travel",
        "description": "Rules and information about travel."
      },
      {
        "label": "Size Categories",
        "value": "rules/size_categories",
        "description": "Details on size categories of creatures and their implications."
      },
      {
        "label": "Language and Communication",
        "value": "rules/language_and_communication",
        "description": "Information on languages and communication methods."
      },
      {
        "label": "Dying",
        "value": "rules/dying",
        "description": "Rules about dying and death in the game."
      }
    ]
  }
];