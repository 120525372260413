import Utopia, { metadata as utopiaMetadata } from './utopia.jsx'
import Worldless, { metadata as worldlessMetadata } from './worldless.jsx'
import Dystopia, { metadata as dystopiaMetadata } from './dystopia.jsx'
import Perfection, { metadata as perfectionMetadata } from './perfection.jsx'
import Revelations, { metadata as revelationsMetadata } from './revelations.jsx'
import Hyperion, { metadata as hyperionMetadata } from './hyperion.jsx'

export const stories = [
  {
    component: Utopia,
    metadata: utopiaMetadata
  },
  {
    component: Worldless,
    metadata: worldlessMetadata
  },
  {
    component: Dystopia,
    metadata: dystopiaMetadata
  },
  {
    component: Perfection,
    metadata: perfectionMetadata
  },
  {
    component: Revelations,
    metadata: revelationsMetadata
  },
  {
    component: Hyperion,
    metadata: hyperionMetadata
  },
]
