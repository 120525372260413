import {
  SimpleGrid,
  Card,
  Group,
  Button,
  Badge,
  Text,
  Title,
  Flex,
  Container,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DOMPurify from 'dompurify';
import { stories } from '../content/stories';


const Stories = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();  

  // useEffect(() => {
  //   setStories(getStories());
  // }, []);

  if (searchParams.get("id") != null) {
    const index = stories.findIndex(f => f.metadata.id === searchParams.get("id"))
    const Component = stories.at(index).component;
    const accent = stories.at(index).metadata.accent;
    console.log(index);

    var currentWidth = "100%";

    return (
      <Container className="story">
        <Button fullWidth onClick={() => navigate('/myramyth/stories')} c="black" bg={accent}>Back</Button>
        <Component />
      </Container>
    )
  }
  else {
    return (
      <SimpleGrid cols={{ base: 1, sm: 2, md: 3, lg: 4 }}>
        {stories.map(({ component: StoryComponent, metadata }) => (
          <Card
            shadow="sm"
            padding="md"
            radius="md"
            withBorder
            key={metadata.id}
          >

            <Group justify="space-between" mt="md" mb="xs">
              <Text fw={500}>{metadata.title}</Text>
              <Badge bg={metadata.accent} c={metadata.foreground}>{metadata.date}</Badge>
            </Group>

            <Text size="sm" c="dimmed">
              <i>{metadata.snippet.substring(0, 250)}...</i>
            </Text>

            <Button bg={metadata.accent} c={metadata.foreground} fullWidth mt="md" radius="md" onClick={() => { 
              navigate(`/myramyth/stories?id=${metadata.id}`)
            }}>
              Continue Reading
            </Button>
          </Card>
        ))}
      </SimpleGrid>
    )
  }
};

export default Stories;
