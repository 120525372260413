import React from 'react';
import { Title, Text } from '@mantine/core';

const Story = () => (
  <div>
    <Title order={1} tt="uppercase" fs="bold">{metadata.title}</Title>
    <Title order={3} fs="bold">{metadata.date}</Title>
    <Title order={3} fs="bold" c={metadata.accent}>S-X.0.0</Title>
    <Title order={3} fs="bold">2024</Title>
    <Text>{`“They’re following me again,” he said quietly to himself. His backpack was barely holding on to his shoulder while the zipper was desperately keeping his numerous books at bay. His eyes darted across the halls, consuming every bit of information there was available, slowly overwhelming his mind.

Unlike most of the students here, his mind filled with much more than just what his eyes and ears derived. Rather, glowing figures would float alongside him. Dark specters chanted in tongues just outside the office, and a cloaked figure with a cybernetic arm and leg was constructing some type of machine.

It was hard for him to tell what was real, or even if it really mattered in the first place. He could tell that people saw him as crazy, treated him like he needed additional assistance, almost like they were going to put him down soon. As if he was some burden on society.

Inside one of the open lockers was a mirror; he got a glimpse of himself for just a moment. He was a mess.

His pin-straight blonde hair was starting to gray with his age, yet it seems like it was long coming, being 50 years old and all. His scruffy goatee was patchy from the last time he tried to shave. The wendigo wouldn’t let him finish so he finally gave up and went to bed.

The girl with red eyes closed her locker, leading him to look further down the hall, then at her. She raised an eyebrow at him, “Tough morning, huh?”

He let out a relieved breath, realizing now how long he’d been holding it in. His hands trembled a bit, but he stood up straight, “Nothing I can’t handle!”

She snickered a bit, “Alright, tough guy.” She checked the schedule she tucked away in her back pocket, “I’ve got one more class, don’t do anything too crazy while I’m gone, alright?”

He nervously nodded his head before she waved him goodbye. Once she was gone, he tucked his chin down to his chest and made his way back to his dorm. He felt split in half; sometimes the people in one world would talk to him, and sometimes the others. Worse even, he couldn’t really tell who was who. He made the mistake of doing his recent report on the history of occulants, an assignment which he failed.

Note to self: humans don’t know about non-humans, with the exceptions of dogs, cats, cows, and spiders. He felt confident that that was a good rule of thumb.

—

He dropped his backpack at the foot of his bed, his textbooks, journals, and various notes sprawling out across the floor once the zipper finally gave way.

His dorm was a decent size, big enough for his twin size bed and gigantic desk that he used for drawing, blueprinting, and ultimately trying to make sense of everything around him. He had a roommate for a short while, but as they all do, they moved rooms pretty quickly.

He locked the door. Generally, when he did that, it did a great job of separating the two worlds. The human-humans wouldn’t bother him when they couldn’t open the door, leaving him to the humans-and-other-things side. Recently, he had been referring to them as the Gowai and the Cohere, respectively. The Gowai housed the humans that constantly say, “Go away”, while the Cohere felt more cohesive and recognizable, also with humans that say “Come here” a lot.

He had about an hour before the knock would come, so he drew. This was one of his favorite ways to pass his time when he was alone; drawing what he saw. He did a very good job of separating his Gowai and Cohere utensils, as when he drew with one, the others wouldn’t see it. And that was his favorite part: he’d draw them backwards.

After some meticulous planning, he’d pick something in the Gowai and draw it in the Cohere, and then again vise versa, all in the same drawing. It’s somewhat sad that neither will be able to see both, but at least they’re both looking at something.

Unless he drew the same thing with both.

Maybe if he drew the same picture, they’d both be able to see it. Sure, they wouldn’t be looking at quite the same thing, but it would be ultimately the same. Maybe they’d be able to almost communicate with each other, perhaps it would feel a bit more cohesive then. It'd be a little easier to understand it all.

He got so excited by his idea that he tripped over the box underneath his bed. The top flew off and its contents sprawled out beneath him: a series of belongings that he had discovered with unknown origin. Things that he was still contemplating, deciding whether they belonged in the Gowai or the Cohere.

He hastily shoveled them back into their box; a series of small cards covered in art depicting flames and ice and orcs and elephants. He felt pretty confident that those belonged in the Cohere, but he needed to be sure. A small device that seemed to create fire at will, still very up for debate. One, he was told, would take the user between different timelines, but it never actually worked; he needed to throw that one away. And his favorite of all: the polaroid camera he gave to his daughter when she was just learning to walk. It’s unfortunate that he had to take it back so soon.

After finally tossing everything back in haphazardly, he shunted it back under his bed and moved to his desk. Most of the ruffled pages had writing on them, but he scavenged long enough to find a piece with an open portion on it. One half of the page was covered, but the other half was free for use.

He sat down and opened a drawer on either side of him: his Gowai drawer and his Cohere drawer. Gowai was always on the left, Cohere was always on the right. He decided it was an important distinction to make, for when he was getting confused, which was rather often.

Each drawer had its own types of inks and writing tools; the Gowai drawer had long plastic ink-machines while the Cohere predominantly had pigments and lasers. He did learn, though, that the lasers don’t always work on paper.

“Maybe plastic is Gowai specific,” he muttered to himself as he set up his station.

After setting everything exactly where it belonged, each utensil on either side of the page now atop the stack of pages, he hesitated. It dawned on him that he didn’t know what to draw, really. What would make sense to both worlds? He’s never seen something that exists in both world, so what could–

His eyes widened and he audibly gasped, suddenly dashing back to the box he toppled earlier. Quickly, he grabbed the camera he had laid on top, looked directly into the lens, and snapped a photo.

—

The knocking on the door rang his ears. Has it been an hour already? He checked the digital clock in his window sill, it had actually been an hour and a half.

He quickly shuffled to his feet, grabbing the door and letting the girl with red eyes inside. She smiled at him, laying her bag down against the wall next to the door, where she always did.

She flopped down onto his messy bed, “How’s the brain and the eyes been holdin’ up, Max?”

His tremors had been consistently rummaging his body since they last spoke, shaking his voice a small amount, “I’ve been worse, that’s for sure.” She gave him a suspicious glare as she sat up, but he retorted, “Good enough that I had an idea!”

She perked up inquisitively for a moment, raising an eyebrow. He turned around and quickly picked up the sheet of paper he’d been scribbling on. He lifted it up, retracted it, folded it in half so only his self-portrait was visible, then presented it again. She cocked her head at it before her eyes widened. “Is this in both?”

He nodded his head furiously, “It’s the first time! We’ll all see the same thing!” His voice was as giddy as it was shaky. She lightly grasped at it, releasing it from his hands. She stared at it for a short while, deducing the features he drew, wondering to herself what the other side may look like. Hypothetically it was the same tight jawline, scraggly beard, thin-framed glasses and all, but she wanted to see for herself. She looked at him with curiosity, “Would it be okay for me to show the others?”

He paused for a moment, pondering to himself. He hesitated, “If you think that’s a good idea, I guess that’s fine.”

She nodded with reassurance, “I do, and I appreciate it.” She tucked it neatly into her backpack against the wall, specifically one of the portions that would be kept safe on the way home. Max rolled a couple of times through her mind, a man double her age that saw so much. Especially with such a large deadline looming over him at all times, what do you do in a position like his?

As if a bolt of lightning at the base of her spine, she quickly straightened up with a stifled breath, immediately turning around to face him. He cocked his head up to match her gaze, his glasses slightly falling off to one side. She went to speak, stopped to think, then finally said, “What if you wrote it all down?” He cocked his head while she began to pace, “Like, like, what if you wrote down what you saw in both the Gowai and the Cohere? You would have years worth of stories!”

He touched the tip of his finger and thumb to his beard for a moment, scratching beneath his jaw. She continued excitedly, “All the stories you tell me, do you know how fast I would get through all of that? And if it’s as real as it is to you, it would be like nothing else anybody has ever seen before. I mean, right?”

Everything sprawled through his mind at lightspeed, him furiously trying to keep each world distinct. It was a struggle to say the least, but a struggle he could manage. His chin slowly moved into an elongated nod, “Yeah. Yeah, I can do that.”

—

The sun was being consumed by the horizon and his eyelids were growing heavier. Alone in his dorm, slipped under the covers, and his brain doing backflips, he journeyed to whatever lay behind his eyelids.

As he swept his legs to get into his bed, one of his figurines fell off the shelf. One he designed some time ago, modeled in class. It had dark hair and deep gray eyes, a painfully cold expression. As it fell to the ground, the head separated from the body.

He cursed a bit to himself before placing it back where it was, now in two pieces. He decided he’d tend to it later.

He had a story to write, there wasn’t too much time left. The important thing is that she read it, perhaps. Tori, the girl with red eyes.
`}</Text>
  </div>
)

export const metadata = {
  title: 'Revelations',
  author: 'Orident',
  date: 'Sept 2, 2024',
  accent: '#ff0000',
  foreground: 'black',
  id: 'revelations',
  snippet: "We meet Max, a young adult finding his way through college life with extreme hallucinations haunting him. His dorm is scattered with toys and comics, one notably of Alyx Ploht. He is traveling down the halls, back from class, with one of his colleagues, Tori (unnamed), talking about his recent experiences: hearing voices about the end and new beginnings of an entire world. Some huge behemoth. She tells him that he should write it all down, maybe make a story out of it. He returns to his dorm to do just that; by the end of it, the head of the Alyx figure breaks."
}

export default Story;