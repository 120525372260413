import { useState } from "react";
import { motion } from "framer-motion";
import { descriptions, data } from "../content/compendium/index.js";
import {
  Group,
  Button,
  Burger,
  Text,
  Tree,
  Grid,
  Container,
  TextInput,
  useTree,
  Drawer,
  ScrollArea,
  Affix,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";

const BlankDescription = () => {
  return <></>;
};

function deepFilter(array, predicate) {
  return array.reduce((result, item) => {
    // Check if the current item matches the predicate
    const shouldInclude = predicate(item);

    // Recursively filter the children if they exist
    let filteredChildren = [];
    if (item.children) {
      filteredChildren = deepFilter(item.children, predicate);
    }

    // Include the item if it matches the predicate or has matching children
    if (shouldInclude || filteredChildren.length) {
      const newItem = { ...item };

      // Only include the children if there are any after filtering
      if (filteredChildren.length) {
        newItem.children = filteredChildren;
      } else {
        delete newItem.children;
      }

      result.push(newItem);
    }

    return result;
  }, []);
}

function Compendium() {
  const smallSize = useMediaQuery("(max-width: 1200px)");
  const [opened, { toggle }] = useDisclosure();
  const [searchValue, setSearchValue] = useState(data);
  const tree = useTree();
  const [DescriptionComponent, setDescriptionComponent] = useState(
    () => BlankDescription
  );
  const [treeNode, setTreeNode] = useState(null);

  const setSearch = (search) => {
    const searchPattern = search.toLowerCase();
    console.log(searchPattern);
    const newData = deepFilter(
      data,
      (f) =>
        f.label.toLowerCase().includes(searchPattern) ||
        f.value.toLowerCase().includes(searchPattern)
    );
    console.log(newData);
    setSearchValue(newData);
  };

  const setDescription = (node) => {
    const index = descriptions.findIndex((f) => f.id === node.value);
    if (index > -1) {
      const component = descriptions.at(index).component;
      setDescriptionComponent(() => component);
    }
    setTreeNode(node);
  };

  return (
    <>
      {smallSize && (
        <>
          <Affix position={{ bottom: 16, left: 16 }}>
            <Button
              onClick={toggle}
              disabled={opened}
              aria-label="Toggle compendium navigation"
            >
              Open Compendium
            </Button>
          </Affix>
          <div className="mobile-description">
            <DescriptionComponent element={treeNode}/>
          </div>
        </>
      )}

      {!smallSize && (
        <motion.div
          key="compendium"
          initial={{ opacity: 0, y: -500 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -500 }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 20,
            duration: 0.1,
          }}
          className="compendium-grid"
        >
          <Grid>
            <Grid.Col span={3}>
              <div>
                <TextInput placeholder="Search" onChange={(event) => setSearch(event.currentTarget.value)}></TextInput>

                <Tree
                  data={searchValue}
                  tree={tree}
                  className="item-tree"
                  renderNode={({
                    node,
                    selected,
                    expanded,
                    hasChildren,
                    elementProps,
                  }) => {
                    if (selected) {
                      setDescription(node);
                    }
                    return (
                      <Group gap={5} {...elementProps}>
                        <span style={{ paddingLeft: "5px" }}>{node.label}</span>
                      </Group>
                    );
                  }}
                  selectOnClick="true"
                />
              </div>
            </Grid.Col>
            <Grid.Col span={8} className="description">
              <DescriptionComponent element={treeNode}/>
            </Grid.Col>
          </Grid>
        </motion.div>
      )}
      <Drawer
        opened={opened}
        onClose={toggle}
        size="xs"
        withCloseButton={false}
        padding="sm"
      >
        <TextInput placeholder="Search" onChange={(event) => setSearch(event.currentTarget.value)}></TextInput>
        <Tree
          data={searchValue}
          tree={tree}
          className="item-tree"
          renderNode={({
            node,
            selected,
            expanded,
            hasChildren,
            elementProps,
          }) => {
            if (selected) {
              setDescription(node);
            }
            return (
              <Group gap={5} {...elementProps}>
                <span style={{ paddingLeft: "5px" }}>{node.label}</span>
              </Group>
            );
          }}
          selectOnClick="true"
        />
      </Drawer>
    </>
  );
}

// function Compendium() {
//   const categories = Object.keys(data);
//   const [hoveredItemKey, setHoveredItemKey] = useState(null);
//   const width = window.innerWidth
//   const height = window.innerHeight;

//   if (width < 400) {
//     return (
//       null
//     )
//   }
//   else {
//     return (
//       <div className="row">
//         {categories.map((category) => (
//           <div className="col-4" key={category}>
//             <h2>{capitalize(category)}</h2>
//             <ul className="item-list">
//               {data[category].map((item) => (
//                 <motion.li
//                   key={item.key}
//                   initial={{ scale: 1, borderColor: "rgba(255,255,255,0)" }}
//                   whileTap={{ scale: 0.9, backgroundColor: "rgba(255,255,255,0.1)"  }}
//                   whileHover={{ scale: 1.05, borderColor: "rgba(255,255,255,1)" }}
//                   onMouseEnter={() => {
//                     const desc = document.getElementById('description-box');
//                     desc.innerHTML = "<p>" + item.description + "</p>";
//                     desc.style.display = "block";
//                   }}
//                   onMouseLeave={() => {
//                     const desc = document.getElementById('description-box');
//                     desc.innerHTML = "<p>" + "</p>";
//                     desc.style.display = "none";
//                   }}
//                   transition={{ duration: 0.1 }}
//                   className="list-item"
//                 >
//                   {hoveredItemKey != item.key && (
//                     capitalize(item.name)
//                   )}
//                   {hoveredItemKey === item.key && (
//                     <motion.li >
//                       <p>
//                         {capitalize(item.name)}
//                         <br/>
//                         <span className="description">{item.description}</span>
//                       </p>
//                     </motion.li>
//                   )}
//                 </motion.li>
//               ))}
//             </ul>
//           </div>
//         ))}
//       </div>
//     );
//   }
// }

function capitalize(string) {
  return (
    string.charAt(0).toUpperCase() +
    string
      .slice(1)
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([a-z])(,)/g, "$1$2 ")
  );
}

export default Compendium;
