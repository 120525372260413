import { Container, Title, Stack, ScrollArea, Button, Flex, Center } from "@mantine/core";
import Traits from "../../components/Traits";
import { color } from "framer-motion";

const Description = ({ element }) => {
  const traits = element.traits;
  const gifts = element.gifts;
  const scores = element.scores;

  console.log(element);
  console.log(gifts);

  gifts.map((gift) => {
    console.log(gift);
  })

  return (
    <>
      <ScrollArea>
        <Flex direction={"column"}>
          <Stack justify="center" align="center">
          <Title order={1}>Regal Oxtus</Title>

            <Flex gap={20} direction={"row"}>
              {gifts.map((gift) => (
                <Button bg="green">{gift}</Button>
              ))}
            </Flex>
            <Flex gap={20} direction={"row"}>
              {scores.map((score) => (
                <Button bg="blue">{score}</Button>
              ))}
            </Flex>
          </Stack>
        </Flex>
      </ScrollArea>
    </>
  )
}

export default Description;