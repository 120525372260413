import { AnimatePresence, motion } from "framer-motion";
import { Utopia_White } from "../content/img";

const UtopiaMain = () => (
  <motion.div
    initial={{ opacity: 0, y: 500 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 500 }}
    transition={{ type: "spring", stiffness: 100, damping: 20, duration: 0.1 }}
    className="utopia-main"
  >
    <img src={Utopia_White} className="logo" alt="Utopia Logo" />
    <h1>Utopia</h1>
    <p>Welcome to Utopia. The class-less TTRPG for a class-less future.</p>
  </motion.div>
);


export default UtopiaMain;