import React, { useEffect, useState, useRef } from 'react';
import '../css/Utopia.css';
import '../css/Galaxy.css';

const colors = [
  [62, 35, 255],
  [60, 255, 60],
  [255, 35, 98],
  [45, 175, 230],
  [255, 0, 255],
  [255, 128, 0]
];

const gradientSpeed = 0.002;
const maxTime = 20;
const minTime = 3;

function randomTime(mintime, maxtime) {
    return Math.floor(Math.random() * (maxtime - mintime + 1) + mintime);
}

function changeAnimationTime(element) {
    var random = randomTime(maxTime);
    element.style.animation = "peek " + random + "s infinite";
}

const Stars = () => {
  const galaxyRef = useRef(null);

  useEffect(() => {
    const generateStars = () => {
      const starCount = 250;
      const galaxy = galaxyRef.current;

      for (let i = 0; i <= starCount; i++) {
        const xPosition = Math.random() * galaxy.offsetWidth;
        const yPosition = Math.random() * galaxy.offsetHeight;
        const starType = Math.floor(Math.random() * 3) + 1;

        const star = document.createElement('div');
        star.className = `star star-type${starType}`;
        star.style.top = `${yPosition}px`;
        star.style.left = `${xPosition}px`;
        switch (starType) {
            case 1: 
                star.style.animation = "twinkle_one " + randomTime(minTime, maxTime) + "s ease-in-out infinite";
                break;
            case 2: 
                star.style.animation = "twinkle_two " + randomTime(minTime, maxTime) + "s ease-in-out infinite";
                break;
            case 3: 
                star.style.animation = "twinkle_three " + randomTime(minTime, maxTime) + "s ease-in-out infinite";
                break;
            default:
                break;
        }
        

        galaxy.appendChild(star);
      }
    };

    generateStars();
  }, []);

  return <div ref={galaxyRef} className="galaxy"></div>;
};

const Galaxy = () => {
  return (
    <div className="app">
      <Stars />
      {/* Additional content or components can be added here */}
    </div>
  );
};

export default Galaxy;
