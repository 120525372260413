import { Title, Spoiler, Text, Container, Stack, ScrollArea, Button } from "@mantine/core";

const Description = () => {
  return (
    <>
      <Title order={1}>Oxtus</Title>
      <ScrollArea>
        <Stack className="blur-edges">
          <Spoiler maxHeight={60} showLabel="Show more" hideLabel="Hide">
            <Stack>
              <Text size="lg">Oxtii are humanoid fauna-like alien creatures. They are able to take advantage of biological spores that they eject from their body, allowing them to communicate mentally with creatures that the spores attach to from short distances. This is their sole form of communication. Some subspecies of oxtii have spores with more purposes, such as the release of specific pheromones. This species is ancient and well-adapted into Utian and Pian society, though their origins are still debated.</Text>
              <Text size="lg">Oxtii function very differently based on their subspecies, sharing a powerful healing capability in common. Albeit slow, any form of natural healing capability is rare to see and very powerful, specifically that of healing DHP. The three subspecies of Oxtii allow for a better experience in a specific playstyle, but don’t offer maneuverability in this sense.</Text>
            </Stack>
          </Spoiler>
          <Title order={4}>Location</Title>
          <Text size="lg">Oxtii claim their original residence on the planet of Oxtane, a planet in a neighboring solar system that was destroyed post their departure. They can be found wandering predominately throughout the streets of Uto, though dense populations exist in warm, floral areas of Pia.</Text>
          <Title order={4}>Lifespan and Genetics</Title>
          <Spoiler maxHeight={60} showLabel="Show more" hideLabel="Hide">
            <Stack>
              <Text size="lg">Oxtus biology resembles that of plants and fungi on Pia as well as some level of alien DNA. This allows them to use a more advanced photosynthesis called hyperphotosynthesis.</Text>
              <Text size="lg">Oxtii do have genders, though there is no specific difference between the genders, other than that similar genders cannot breed. Reproduction is done between oxtii asexually, and all breeding must include at least one regal oxtus, as they are the only ones capable of producing sperm.</Text>
              <Text size="lg">Some oxtii care for and nurture their young, whereas others have been seen leaving their youth stranded. There are no recorded patterns based on location or species.</Text>
            </Stack>
          </Spoiler>
          <Title order={4}>Background</Title>
          <Spoiler maxHeight={60} showLabel="Show more" hideLabel="Hide">
            <Stack>
              <Text size="lg">The oxtus population has a dense oral history, though none of the claims have been considered provable yet. The reason for their planet's extinction has been lost to history, and some strong minds have claimed the oxtii lore "too convenient," as their biological composition resembles many lifeforms on Pia. This is often combatted with the presence of some alien physiology, though some chalk this up to evolution and the existence of mana.</Text>
              <Text size="lg">Even though Oxtane had never been officially discovered, the solar system they claimed it was within had been seen and their accounts of it prior were accurate. Some floating debris and damages show signs of what may have been a livable planet, but this conclusion is still heavily debated.</Text>
            </Stack>
          </Spoiler>
          <Title order={3}>Playing an Oxtus</Title>
          <Spoiler maxHeight={60} showLabel="Show more" hideLabel="Hide">
            <Stack>
              <Text size="lg">Oxtii are very unique in their ability to heal, having not only a self-regenerating ability, but the ability to convert water into a minor form of healing and stamina restoration. The regal oxtus is a naturally social creature and shares some basic talents that allow them to regain stamina and resist influence. The brazen oxtus has some of the most powerful combatants, and to contrast the astute oxtus is extremely smart, having two separate heads and a heightened ability to craft and cast spells.</Text>
              <Text size="lg">The oxtus talent tree offers one of the only talents that allows a supply of deep health. Although this is somewhat limited, the ability of medical regeneration is quite unique. Outside of this, they also have distinctive ways of regaining both health and stamina.</Text>
            </Stack>
          </Spoiler>
        </Stack>
      </ScrollArea>
    </>
  )
}

export default Description;