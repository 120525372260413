import { motion, useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';

const Dice = ({ diceId, diceValue, updateDiceValue }) => {
  const controls = useAnimation();

  const roll = () => {
    const newValue = Math.floor(Math.random() * 6 + 1);
    if (!isNaN(newValue)) {
      updateDiceValue(diceId, newValue);  // Pass value to parent

      controls.set({ scale: 1, rotate: 0 });
      controls.start({
        rotate: 360,
        transition: { duration: 0.25, stiffness: 500, bounce: 0.1, type: 'spring' },
      });
    }
  };

  useEffect(() => {
    roll();
  }, []);

  return (
    <motion.div animate={controls} onClick={roll} className="dice">
      {diceValue}
    </motion.div>
  );
};

export default Dice;
