import { AppShell, NavLink, Burger, Container, SimpleGrid, Card, Image, Group, Button, CardSection, Badge, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import Stories from '../components/Stories';
import MyramythMain from '../components/MyramythMain';
import { Myramyth_FullWidth, Myramyth_White } from '../content/img';
import '../content/css/Myramyth.css';

const navData = [
  { label: 'Home Page', rightSection: '>', location: '/myramyth/home' },
  { label: 'Short Stories', rightSection: '>', location: '/myramyth/stories' },
  { label: 'Merchandise (Soon)', rightSection: '>', location: '/myramyth/merch', disabled: true },
  { label: 'Privacy Policy (Soon)', rightSection: '>', location: '/myramyth/privacy-policy', disabled: true },
];

function Myramyth() {
  const navigate = useNavigate();
  const location = useLocation();
  const [opened, { toggle }] = useDisclosure();

  const navItems = navData.map((item, index) => (
    <NavLink
      href="#"
      key={item.label}
      active={location.pathname.includes(item.location)}
      label={item.label}
      disabled={item.disabled}
      rightSection={item.rightSection}
      onClick={() => {
        navigate(item.location);
        toggle();
      }}
    />
  ));

  return (
    <AppShell
      header={{ height: 100 }}
      navbar={{
        width: 300,
        breakpoint: 'sm',
        collapsed: { mobile: !opened },
      }}
      padding="md"
    >
      <AppShell.Header>
        <Burger
          opened={opened}
          onClick={toggle}
          hiddenFrom="sm"
          size="lg"
          className='myramyth-burger'
        />
        <Link to={'/'}>
          <div className='myramyth-logo'>
            <img src={Myramyth_FullWidth} height='100'></img>
          </div>
        </Link>
      </AppShell.Header>

      <AppShell.Navbar p="md" gap="md" size="sm">
        {navItems}
      </AppShell.Navbar>
      
      <AppShell.Main>
        <Routes>
          <Route
            path="/home"
            element={
              <MyramythMain />
            }
          />
          <Route
            path="/stories"
            element={
              <Stories />
            }
          />
        </Routes>
      </AppShell.Main>
    </AppShell>
  );
}

export default Myramyth;